import { useNavigate } from "react-router-dom";
import Barra from "../Componentes/Barra";
import { useAuth } from "../Hooks/Autorizacion";
import { useState } from "react";
import * as asn1js from "asn1js";
import * as pkijs from "pkijs";
import axios from "axios";
import useFetchEstado from "../Hooks/EstadoCargue";
import { useEffect } from "react";

const Subir = () => {
  const user = useAuth();
  const Navigate = useNavigate();
  const [archivoSeleccionado, setArchivoSeleccionado] = useState(null);
  const [AlertaInvisible, setAlertaInvisible] = useState(true);
  const [MensajeDeAlerta, setMensajeDeAlerta] = useState("");
  const [BotonVisible, setBotonVisible] = useState(false);
  const [OcultarValidado, setOcultarValidado] = useState(true);
  const { estado, isLoading, NombreMuni } = useFetchEstado(user);
  const [DesactivarForm, setDesactivarForm] = useState(true);
  const [AlertaEstadosInvisible, setAlertaEstadosInvisible] = useState(true);
  const [BotonPDF, setBotonPDF] = useState(true);
  const [mostrarSpinner, setMostrarSpinner] = useState(false);

  useEffect(() => {
    // Si el estado es 2, mostrar un mensaje del archivo siendo validado
    switch (estado) {
      case 1:
      case 2:
      case 3:
        setDesactivarForm(true);
        setMensajeDeAlerta(
          "No puedes acceder a esta sección hasta que no termines las validaciones"
        );
        setAlertaEstadosInvisible(true);
        break;
      case 4:
        setDesactivarForm(false);
        setAlertaEstadosInvisible(false);
        break;
      case 5:
        setDesactivarForm(true);
        setBotonPDF(false);
        setMensajeDeAlerta("Has completado el reporte PEDT exítosamente");
        setOcultarValidado(false);
        setAlertaEstadosInvisible(false);
        break;
      default:
        // Si el estado no coincide con ninguno de los casos anteriores
        // No hay necesidad de hacer nada
        break;
    }
  }, [estado]);

  //Controlador para cuando se cambie el archivo seleccionado
  const handleFileChange = async (event) => {
    const archivo = event.target.files[0];
    setArchivoSeleccionado(archivo);
    // Verificar si se ha seleccionado un archivo
    if (archivo) {
      try {
        const fechaCreacion = obtenerFechaCreacion(archivo);
        const InfoCertificado = await DecodificarArchivoP7z(archivo);
        if (InfoCertificado) {
          const { certificados, decodedString } = InfoCertificado;

          let certificadoMasPesado;
          if (certificados.length <= 3) {
            // Si hay tres o menos certificados, obtenemos el más pesado
            certificadoMasPesado = certificados[0];
            for (let i = 1; i < certificados.length; i++) {
              if (
                certificados[i].tbsView.length >
                certificadoMasPesado.tbsView.length
              ) {
                certificadoMasPesado = certificados[i];
              }
            }
          } else {
            // Si hay más de tres certificados, obtenemos el segundo más pesado
            // Creamos una copia del array para evitar modificar el original
            const certificadosOrdenados = [...certificados];
            // Ordenamos los certificados por tamaño de forma descendente
            certificadosOrdenados.sort(
              (a, b) => b.tbsView.length - a.tbsView.length
            );
            // El segundo certificado más pesado será el que esté en la posición 1 del array ordenado
            certificadoMasPesado = certificadosOrdenados[1];
          }
          const DatosCertificado = ObtenerInfoCertificado(certificadoMasPesado);
          try {
            const nombreArchivo = archivo.name;
            const response = await axios.post("/Validarfirma", {
              Fechafirma: fechaCreacion,
              FechafinVigencia: certificadoMasPesado.notAfter,
              IDRes: DatosCertificado.nIdTitular,
              Contenido: decodedString,
              Usuario: user.user,
              NombreArchivo: nombreArchivo,
            });
            if (response.data.success) {
              setOcultarValidado(false);
              setAlertaInvisible(true);
              setMensajeDeAlerta(response.data.message);
              setBotonVisible(true);
            } else {
              setOcultarValidado(true);
              setAlertaInvisible(false);
              setMensajeDeAlerta(response.data.message);
              setBotonVisible(false);
            }
          } catch (error) {
            setOcultarValidado(true);
            setAlertaInvisible(false);
            if (error.response) {
              // Error de respuesta del servidor
              const errorMessage =
                error.response.data.message ||
                "Ha ocurrido un error en el servidor.";
              setMensajeDeAlerta(errorMessage);
            } else if (error.request) {
              // Error de solicitud
              setMensajeDeAlerta("No se pudo conectar al servidor");
            } else {
              // Otros errores
              setMensajeDeAlerta(
                "Ha ocurrido un error. Por favor, inténtelo de nuevo más tarde."
              );
            }
          }
        }
      } catch (error) {
        setAlertaInvisible(false);
        setMensajeDeAlerta("El archivo está corrupto");
        setOcultarValidado(true);
        setBotonVisible(false);
      }
    } else {
      setAlertaInvisible(false);
      setMensajeDeAlerta("No se ha seleccionado ningún archivo !!!");
      setOcultarValidado(true);
      setBotonVisible(false);
    }
  };

  //Obtener la fecha de la creacion del archivo
  const obtenerFechaCreacion = (archivo) => {
    return archivo.lastModifiedDate; // Devuelve la fecha de última modificación del archivo
  };

  // Función para obtener el nombre y el número de identificación del titular del certificado
  const ObtenerInfoCertificado = (certificado) => {
    const NombreTitular = certificado.subject.typesAndValues.find(
      (item) => item.type === "2.5.4.3"
    );
    const NombreEntidadCertificadora = certificado.issuer.typesAndValues.find(
      (item) => item.type === "2.5.4.3"
    );
    let TipoOIDNITMunicipio;
    let TipoOIDTitular;
    let TipoOIDCertificadora;
    switch (NombreEntidadCertificadora.value.valueBlock.value) {
      case ("AC SUB CERTICAMARA", "AC SUB 4096 CERTICAMARA"):
        TipoOIDTitular = "1.3.6.1.4.1.23267.2.2";
        TipoOIDCertificadora = "2.5.4.11";
        TipoOIDNITMunicipio = "1.3.6.1.4.1.23267.2.3";
        break;
      case "CAMERFIRMA COLOMBIA SAS CERTIFICADOS - 002":
        TipoOIDTitular = "2.5.4.5";
        TipoOIDCertificadora = "2.5.4.5";
        TipoOIDNITMunicipio = "2.5.4.97";
        break;
      default:
        TipoOIDTitular = "2.5.4.5";
        TipoOIDCertificadora = "2.5.4.11";
        TipoOIDNITMunicipio = "1.3.6.1.4.1.4710.1.3.2";
        break;
    }
    const NITMunicipioTitular = certificado.subject.typesAndValues.find(
      (item) => item.type === TipoOIDNITMunicipio
    );
    const IdentificacionTitular = certificado.subject.typesAndValues.find(
      (item) => item.type === TipoOIDTitular
    );
    const IdentificacionEntidadCertificadora =
      certificado.issuer.typesAndValues.find(
        (item) => item.type === TipoOIDCertificadora
      );
    const nTitular = NombreTitular
      ? NombreTitular.value.valueBlock.value
      : "No disponible";
    const cNit = NITMunicipioTitular
      ? NITMunicipioTitular.value.valueBlock.value
      : "No disponible";
    const nIdTitular = IdentificacionTitular
      ? IdentificacionTitular.value.valueBlock.value
      : "No disponible";
    const nCertificadora = NombreEntidadCertificadora
      ? NombreEntidadCertificadora.value.valueBlock.value
      : "No disponible";
    const nIdCertificadora = IdentificacionEntidadCertificadora
      ? IdentificacionEntidadCertificadora.value.valueBlock.value
      : "No disponible";
    return { nTitular, cNit, nIdTitular, nCertificadora, nIdCertificadora };
  };

  //Manejo del botón del formulario
  const ManejoSubmit = async () => {
    try {
      // Mostrar el spinner
      setMostrarSpinner(true);
      setMensajeDeAlerta(
        "Subiendo registros, por favor espere, esto puede tardar unos segundos, no salga de esta sección hasta que termine el proceso..."
      );
      setDesactivarForm(true);
      setBotonVisible(false);
      const formData = new FormData(); // Crea un objeto FormData
      formData.append("archivo", archivoSeleccionado);
      const response = await axios.post("/Entrega", formData, {
        headers: {
          "Content-Type": "multipart/form-data", // Establece el encabezado adecuado para el envío de archivos
        },
        params: {
          Usuario: user.user, // Adjunta otros datos necesarios en la URL
        },
      });
      if (response.data.success) {
        setDesactivarForm(true);
        setBotonVisible(false);
        setMensajeDeAlerta("Se envió el archivo firmado correctamente");
        setOcultarValidado(false);
        setBotonPDF(false);
      }
    } catch (error) {
      setMensajeDeAlerta("Ha ocurrido un error en el servidor");
      setAlertaInvisible(false);
    } finally {
      // Ocultar el spinner una vez que se complete la entrega
      setMostrarSpinner(false);
    }
  };

  const DescargaPDF = async () => {
    try {
      const response = await axios.get("/BajarPDF", {
        params: {
          correo: {
            user: user,
          },
        },
        responseType: "blob", // Especificar el tipo de respuesta como un archivo blob
      });

      // Crear un objeto URL del archivo blob recibido
      const url = window.URL.createObjectURL(new Blob([response.data]));

      // Crear un enlace para descargar el archivo
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "EntregaPEDT.pdf"); // Nombre del archivo
      document.body.appendChild(link);
      link.click();

      // Limpiar el objeto URL creado
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error al descargar errores:", error);
    }
  };

  const DecodificarArchivoP7z = async (ArchivoP7z) => {
    try {
      // Lee el archivo .p7z
      const arrayBuffer = await ArchivoP7z.arrayBuffer();

      // Parsea el contenido ASN.1
      const asn1 = asn1js.fromBER(arrayBuffer);
      const cms = new pkijs.ContentInfo({ schema: asn1.result });

      // Verifica que el contenido es una instancia de SignedData
      if (cms.contentType !== "1.2.840.113549.1.7.2") {
        throw new Error("El contenido del archivo no está firmado");
      }

      // Obtiene el contenido firmado
      const signedData = new pkijs.SignedData({ schema: cms.content });

      // Obtener los certificados utilizados para firmar
      const certificados = signedData.certificates;

      // Obtener el contenido encapsulado
      const encapsulatedContentData =
        signedData.encapContentInfo.eContent.valueBeforeDecode;

      // Decodificar el ArrayBuffer a una cadena legible
      const textDecoder = new TextDecoder();
      let decodedString = textDecoder.decode(encapsulatedContentData);
      
      // Eliminar caracteres no deseados al principio hasta que se encuentre un '1|05'
      const posicion = decodedString.indexOf("1|05");
      if (posicion !== -1) {
        decodedString = decodedString.substring(posicion);
      }
      
      return { certificados, decodedString };
    } catch (error) {
      setAlertaInvisible(false);
      setMensajeDeAlerta("El archivo está corrupto");
      setOcultarValidado(true);
      setBotonVisible(false);
      return null;
    }
  };

  return (
    <div className="d-flex flex-column vh-100">
      <Barra />
      <div className="d-flex flex-column align-items-center justify-content-center vh-100 text-light">
        <div className="d-flex justify-content-center">
          <img src={require("../Imgs/logo.png")} alt="Logo" className="w-25" />
        </div>
        <form
          className="form-group text-center p-5"
          style={{ borderRadius: "20px", background: "#326939" }}
        >
          <h1>Sube tu reporte PEDT firmado</h1>
          <label className="my-3" htmlFor="archivo" hidden={DesactivarForm}>
            Municipio a reportar: {NombreMuni}
          </label>{" "}
          <br />
          <input
            hidden={DesactivarForm}
            id="archivo"
            type="file"
            className="form-control-file"
            onChange={handleFileChange}
            accept=".p7z, .firm"
          />
          {isLoading ? (
            <div className="spinner-border text-light" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : (
            <div>
              <div
                className="justify-content-center alert alert-danger my-3"
                role="alert"
                hidden={AlertaInvisible}
              >
                {MensajeDeAlerta}
              </div>
              <div
                className="justify-content-center alert alert-danger my-3"
                role="alert"
                hidden={!AlertaEstadosInvisible}
              >
                {MensajeDeAlerta}
              </div>
              <div
                className="justify-content-center alert alert-success my-3"
                role="alert"
                hidden={OcultarValidado}
              >
                {MensajeDeAlerta}
              </div>
              <div className="form-group text-center mt-3">
                <button
                  type="button"
                  className="btn btn-outline-light"
                  hidden={!BotonVisible}
                  onClick={ManejoSubmit}
                >
                  Realizar entrega
                </button>
                {mostrarSpinner && (
                  <div className="spinner-border text-light" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                )}
              </div>
              <button
                type="button"
                className="btn btn-outline-danger"
                onClick={DescargaPDF}
                hidden={BotonPDF}
              >
                Clic aquí para descargar PDF
              </button>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default Subir;
